<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลลูกค้า
      </v-toolbar-title>
      <v-spacer/>
      <v-btn
          v-if="$store.state.authUser.user_permissions.includes('view_customer')"
          @click="showConfirmDeleteDialog = true" depressed color="error"
          class="mx-0">
        <v-icon left>mdi-delete</v-icon>
        ลบลูกค้า
      </v-btn>
    </v-toolbar>
    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            cols="12"
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="px-4 pt-4 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-row>
              <v-col cols="6">
                <v-toolbar-title class="red--text">ออเดอร์ค้างชำระ ({{ overdue.length }})</v-toolbar-title>
                <small class="grey--text">คลิกที่หมายเลขออเดอร์ เพื่อดูรายละเอียด</small>
              </v-col>
              <v-col cols="6">
                <v-toolbar-title>ออเดอร์ทั้งหมด ({{ orders.length }})</v-toolbar-title>
                <small class="grey--text">คลิกที่หมายเลขออเดอร์ เพื่อดูรายละเอียด</small>
              </v-col>
              <v-col cols="6">
                  <v-data-table
                    :headers="order_headers"
                    :items="overdue"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template #item.id_display="{ item }" class="px-0">
                      <a class="text-decoration-underline" :href="'/order/' + item.id" target="_blank">{{item.id_display}}</a>
                    </template>
                    <template #item.total_price="{ item }" class="px-0">
                      <span class="error--text">{{item.total_price.toFixed(2)}}</span>
                    </template>
                  </v-data-table>
              </v-col>
              <v-col cols="6">
                  <v-data-table
                    :headers="order_headers"
                    :items="orders"
                    :items-per-page="5"
                    class="elevation-1"
                  >
                    <template #item.id_display="{ item }" class="px-0">
                      <a class="text-decoration-underline" :href="'/order/' + item.id" target="_blank">{{item.id_display}}</a>
                    </template>
                    <template #item.total_price="{ item }" class="px-0">
                      {{item.total_price.toFixed(2)}}
                    </template>
                  </v-data-table>
              </v-col>
            </v-row>

            <hr class="my-5"/>

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col cols="3" align-self="center">ชื่อลูกค้า</v-col>
                <v-col cols="5">
                  <v-text-field v-model="name" :rules="[rules.required]" required
                                :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เบอร์</v-col>
                <v-col cols="5">
                  <v-text-field v-model="tel" :rules="[rules.required]" required
                                :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เครดิต</v-col>
                <v-col cols="5">
                  <v-text-field v-model="credit" required
                                :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                suffix="วัน"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เงินเติมคงเหลือ</v-col>
                <v-col cols="5">
                  <v-text-field v-model="prepaid" required
                                disabled
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                suffix="บาท"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ประเภทของลูกค้า</v-col>
                <v-col cols="5">
                  <v-select
                      :disabled="!editable"
                      :rules="[rules.required_select]"
                      outlined
                      dense
                      height="32px"
                      class="rounded mt-3"
                      hide-details="auto"
                      v-model="type"
                      :items="typeOption"
                      placeholder="เลือกหมวดสินค้า"
                      item-text="name"
                      item-value="name"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เลขประจำตัวผู้เสียภาษี</v-col>
                <v-col cols="5">
                  <v-text-field v-model="tax_number" required
                                :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ยอดชำระสูงสุด</v-col>
                <v-col cols="5">
                  <v-text-field v-model="max_purchase_amount"
                                type="number"
                                :disabled="!editable"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>


            <v-row v-if="!editable" class="mt-6 ml-0 mb-6">
              <v-btn depressed @click="editable = true" color="secondary" class="mr-4">
                <v-icon left>
                  mdi-pencil
                </v-icon>
                แก้ไขข้อมูลลูกค้า
              </v-btn>

              <v-btn depressed @click="showPrePaidDialog = true" color="primary">
                <v-icon left>
                  mdi-cash
                </v-icon>
                เติมเงิน
              </v-btn>
            </v-row>

            <v-row v-else class="mt-6 mx-0 mb-6">
              <v-btn depressed @click="editable = false" text class="mr-4">
                ยกเลิก
              </v-btn>
              <v-btn depressed @click="saveEditCustomer()" color="success">
                <v-icon left>
                  mdi-pencil
                </v-icon>
                บันทึกการแก้ไข
              </v-btn>
            </v-row>

            <v-row class="mt-4">
              <v-col align-self="center">
                <span class="mr-2">ที่อยู่ทั้งหมด ({{ customerAddress.length }})</span>
                <v-icon @click="showNewAddressForm = true" color="success">mdi-plus-circle</v-icon>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="searchAddress" height="32px" placeholder="ค้นหาชื่อของที่อยู่" outlined dense
                              hide-details="auto"></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
                :search="searchAddress"
                class="mt-6"
                :header-props="{ sortIcon: null }"
                hide-default-footer
                :items-per-page="-1"
                :headers="addressHeader"
                :items="customerAddress">
              <template v-slot:item.action="{ item }">
                <a v-if="item.latitude" :href="item.latitude" class="mr-2" target="_blank">เปิดแผนที่</a>
                <v-btn @click="deleteConfirmDialog = true;selectedAddress = item" icon color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="showConfirmDeleteDialog" max-width="512">
      <v-card>
        <v-card-title style="font-family: Kanit,serif !important;">
          คุณต้องการที่ลบลูกค้าคนนี้?
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmCancel = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>หากลบไปแล้วจะไม่สามารถกู้คืนได้ แต่ยังสามารถดูย้อนหลังได้</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="showConfirmDeleteDialog = false" text>
            ยังไม่ใช่ตอนนี้
          </v-btn>
          <v-btn @click="deleteCustomer()" color="error">
            <v-icon left>mdi-delete</v-icon>
            ยกเลิกออเดอร์นี้
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="showNewAddressForm" persistent max-width="842">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">
          เพิ่มที่อยู่ใหม่
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-textarea hide-details="auto" class="mb-4" v-model="newAddress" outlined placeholder="ที่อยู่"
                        rows="2"></v-textarea>

            <v-text-field class="mb-6" placeholder="เบอร์โทรศัพท์ของที่อยู่ (ไม่จำเป็น)" v-model="newTelAddress"
                          outlined
                          height="32" hide-details></v-text-field>

            <v-row class="mx-0 mb-6">
              <thai-address-input
                  placeholder="ใส่ตำบล"
                  type="subdistrict"
                  v-model="newSearchAddress"
                  input-class="form-control"
                  @selected="onSearched">
              </thai-address-input>
            </v-row>

            <v-row v-if="newSearchResult" class="mb-2 mx-0">
              <p>{{ newSearchResult.province === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล' }}{{ newSearchResult.subdistrict }}
                {{ newSearchResult.province === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ' }}{{ newSearchResult.district }}
                {{
                  newSearchResult.province === 'กรุงเทพมหานคร' ? 'กรุงเทพมหานคร' : `จังหวัด${newSearchResult.province}`
                }}
                {{ newSearchResult.postalCode }}</p>
            </v-row>
            <div class="mt-2">ระยะทาง</div>
            <v-text-field class="mt-1" v-model="newDistance" required type="number"
                          placeholder="กรอกข้อมูล"
                          outlined height="32px"
                          hide-details="auto"/>

            <div class="mt-2">Google Map Link</div>
            <v-textarea
                placeholder="ลิ้งจาก Google Map"
                hide-details
                rows="2"
                outlined
                v-model="newLinkGoogleMap"
            ></v-textarea>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="showNewAddressForm = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
              color="success"
              @click="showNewAddressForm = false;addNewAddress()"
          >
            เพิ่มที่อยู่ใหม่
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    Confirm Delete address-->
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="625">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">
          นำที่อยู่นี้ออก
        </v-card-title>
        <v-card-text>"{{ showAddressBeforeDelete() }}"</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteConfirmDialog = false" text>
            ยกเลิก
          </v-btn>
          <v-btn @click="removeAddress()" color="error">
            นำออก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    <v-dialog v-model="showMap" persistent max-width="625">-->
    <!--      <v-card>-->
    <!--        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">-->
    <!--          จุดที่ตั้งไว้บนแผนที่-->
    <!--          <v-spacer></v-spacer>-->
    <!--          <v-btn @click="showMap = false" icon>-->
    <!--            <v-icon>-->
    <!--              mdi-close-->
    <!--            </v-icon>-->
    <!--          </v-btn>-->
    <!--        </v-card-title>-->
    <!--        <v-card-text>-->
    <!--          <Map :view-mode="true"-->
    <!--               :lat-lng="{lat:parseFloat(selectedAddress.latitude),lng:parseFloat(selectedAddress.longitude)}"/>-->
    <!--        </v-card-text>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->

    <v-dialog v-model="showPrePaidDialog" persistent max-width="625">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">
          เติมเงินลูกค้า
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4">
            <v-col align-self="center">จำนวนเงิน</v-col>
            <v-col class="text-right">
              <v-text-field v-model="prepaidAmount" outlined placeholder="ใส่จำนวนเงินที่เติม"
                            type="number"
                            height="32px" hide-details="auto"/>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <v-col align-self="center">ชำระผ่าน</v-col>
            <v-col class="text-right">
              <v-select
                  v-model="prepaidType"
                  class="rounded mt-3"
                  :items="paymentSelect"
                  outlined
                  dense
                  height="32px"
                  hide-details="auto"
                  placeholder="เลือกรูปแบบการชำระเงิน"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showPrePaidDialog = false" text>
            ยกเลิก
          </v-btn>
          <v-btn @click="addPrePaid()" color="success">
            เติมเงิน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import Map from "@/components/Map";

export default {
  components: {Map},
  name: "CustomerDetail",
  data() {
    return {
      searchAddress: '',
      valid: true,
      rules: {
        required: value => value.length > 0 || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
      customerDetail: {},
      name: '',
      tel: '',
      credit: 0,
      prepaid: 0,
      type: 'ทั่วไป',
      tax_number: '',
      max_purchase_amount: null,
      overdue: [],
      orders: [],
      typeOption: [{
        name: 'ทั่วไป'
      }],
      customerAddress: [],
      addressHeader: [
        {text: 'ที่อยู่', value: 'address'},
        {text: 'เบอร์โทร', value: 'tel', align: 'center'},
        {text: 'ตำบล/แขวง', value: 'district', align: 'center'},
        {text: 'อำเภอ/เขต', value: 'county', align: 'center'},
        {text: 'จังหวัด', value: 'province', align: 'center'},
        {text: 'รหัสไปรษณีย์', value: 'code', align: 'center'},
        {text: 'ระยะทาง', value: 'distance', align: 'center'},
        {text: '', value: 'action', align: 'center'},
      ],
      showNewAddressForm: false,
      newAddress: '',
      newTelAddress: '',
      newSearchAddress: '',
      newSearchResult: {},
      newDistance: 0,
      newLatlngSelect: {},
      newLinkGoogleMap: '',
      editable: false,
      selectedAddress: {},
      deleteConfirmDialog: false,
      showMap: false,
      showPrePaidDialog: false,
      prepaidAmount: 0,
      prepaidType: 'โอนเงิน',
      paymentSelect: [
        {
          text: 'เงินสด',
          value: 'เงินสด'
        },
        {
          text: 'โอนเงิน',
          value: 'โอนเงิน'
        },
      ],
      showConfirmDeleteDialog: false,
      order_headers: [
        { text: 'เปิดเมื่อ',value: 'order_date' },
        { text: 'ออเดอร์',value: 'id_display' },
        { text: 'ราคารวม',value: 'total_price' },
        { text: 'วันกำหนดจ่าย',value: 'due_date' },
        { text: 'สถานะ',value: 'payment_status' },
      ]
    }
  },
  created() {
    this.getCustomer()
  },
  methods: {
    onSearched(address) {
      this.newSearchAddress = address.subdistrict
      this.newSearchResult = address;
    },
    getDistance(value) {
      this.newDistance = value.distance
      this.newLatlngSelect = value.latlng
    },
    showAddressBeforeDelete() {
      return `${this.selectedAddress.address} ${this.selectedAddress.district} ${this.selectedAddress.county} ${this.selectedAddress.province} ${this.selectedAddress.code}`
    },
    getCustomer() {
      axios.get(`/api/accounts/customer/${this.$route.params.id}/`)
          .then(res => {
            console.log(res.data.data)
            this.customerDetail = res.data.data
            this.customerAddress = this.customerDetail.customer_address
            this.name = this.customerDetail.name
            this.tel = this.customerDetail.tel
            this.credit = this.customerDetail.credit
            this.prepaid = this.customerDetail.prepaid
            this.type = this.customerDetail.type
            this.tax_number = this.customerDetail.tax_number
            this.max_purchase_amount = this.customerDetail.max_purchase_amount
            this.overdue = this.customerDetail.overdue.overdue
            this.orders = this.customerDetail.overdue.orders
          })
          .catch(e => {
            console.log(e)
          })
    },
    saveEditCustomer() {
      let payload = {
        id: this.$route.params.id,
        name: this.name,
        tel: this.tel,
        credit: this.credit,
        prepaid: this.prepaid,
        type: this.type,
        tax_number: this.tax_number,
        customer_address: [],
        max_purchase_amount: this.max_purchase_amount
      }
      console.log(payload)
      axios.put('/api/accounts/customer/', payload)
          .then(_ => {
            this.editable = false
            this.$store.state.snackbar = {
              text: 'แก้ไขข้อมูลสำเร็จ',
              visible: true,
              color: 'success'
            }
            this.getCustomer()
          })
          .catch(e => {
            console.log(e)
            this.$store.state.snackbar = {
              text:
                "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : " +
                e.response.status +
                " " +
                e.response.data,
              visible: true,
              color: "error",
            };
          })
    },
    addNewAddress() {
      console.log(this.newSearchResult)
      let payload = {
        customer_id: this.$route.params.id,
        address: this.newAddress,
        latitude: this.newLinkGoogleMap || '',
        longitude: this.newLatlngSelect.lng || '',
        distance: parseFloat(this.newDistance).toFixed(2) || 0,
        is_tax_address: true,
        district: this.newSearchResult.subdistrict || '',
        county: this.newSearchResult.district || '',
        province: this.newSearchResult.province || '',
        code: this.newSearchResult.postalCode || '',
        tel: this.newTelAddress
      }
      // if (this.newSearchResult) {
      //   payload.district = this.newSearchResult.subdistrict
      //   payload.county = this.newSearchResult.district
      //   payload.province = this.newSearchResult.province
      //   payload.code = this.newSearchResult.postalCode
      // }
      console.log(payload)

      axios.post('/api/accounts/customer_address/', payload)
          .then(res => {
            console.log(res.data.data)
            this.$store.state.snackbar = {
              text: 'เพิ่มที่อยู่ใหม่สำเร็จ',
              visible: true,
              color: 'success'
            }
            this.getCustomer()
          })
          .catch(e => {
            console.log(e)
          })
    },
    removeAddress() {
      axios.delete(`/api/accounts/customer_address/${this.selectedAddress.id}`)
          .then(res => {
            console.log(res.data.data)
            this.deleteConfirmDialog = false
            this.$store.state.snackbar = {
              text: 'ลบที่อยู่สำเร็จ',
              visible: true,
              color: 'success'
            }
            this.getCustomer()
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
      // let index = this.customerAddress.indexOf(this.selectedAddress)
      // this.customerAddress.splice(index, 1)
      // this.deleteConfirmDialog = false
    },
    addPrePaid() {
      let payload = {
        customer_name: this.name,
        tel: this.tel,
        delivery_type: "ลูกค้ามารับ",
        payment_method: this.prepaidType,
        payment_status: "ชำระแล้ว",
        order_status: "สำเร็จ",
        discount: 0,
        hasVat: false,
        remark: "",
        is_prepaid: false,
        order_item: [
          {
            product_id: null,
            name: "เติมเงิน",
            quantity: 1,
            unit: "รายการ",
            discount: 0,
            total_price: this.prepaidAmount,
            commission_rate: 0,
            commission_price: 0
          },
          {
            product_id: null,
            name: "ค่าส่ง",
            quantity: 1,
            unit: "ครั้ง",
            discount: 0,
            total_price: 0,
            commission_rate: 0,
            commission_price: 0
          }
        ],
        trip: []
      }
      console.log(payload)
      axios.post('/api/orders/prepaid/', payload)
          .then(() => {
            this.showPrePaidDialog = false
            this.prepaidAmount = 0
            this.prepaidType = 'โอนเงิน'
            this.$store.state.snackbar = {
              text: 'บันทึกการเติมเงินสำเร็จ',
              visible: true,
              color: 'success'
            }
            this.getCustomer()
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    deleteCustomer() {
      axios.delete(`/api/accounts/customer/${this.$route}/`).then(() => {
        this.$store.state.snackbar = {
          text: 'ลบข้อมูลลูกค้าสำเร็จ',
          visible: true,
          color: 'success'
        }
      }).catch(e => {
        this.$store.state.snackbar = {
          text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
          visible: true,
          color: 'error'
        }
      })
    },
    gotoOrder (row) {
      let routeData = this.$router.resolve({path: `/order/${row.id}`});
      window.open(routeData.href, '_blank');
    }
  }
  // async addCustomer() {
  //   await this.$refs.form.validate()
  //   if (this.valid) {
  //     let payload = {
  //       name: this.name,
  //       tel: this.tel,
  //       credit: parseInt(this.credit),
  //       prepaid: parseInt(this.prepaid),
  //       type: this.type,
  //       tax_number: this.tex_number
  //     }
  //     axios.post('/api/accounts/customer/', payload)
  //         .then(res => {
  //           console.log(res.data.data)
  //         })
  //         .catch(e => {
  //           console.log(e)
  //         })
  //   }
  //
  // }
}
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 0 20px 0 !important;
}
</style>

<style>


.form-control {
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  width: 100%;
}

.suggestion-list {
  text-align: left;
  padding: 4px;
  position: fixed;
  background-color: white;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 4px;
}

.suggestion-list-item:hover {
  cursor: pointer;
  background-color: #c7ddef;
}

.suggestion-list {
  max-height: 200px;
  overflow: auto;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
